// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

import "../css/application.css"

import jump from "jump.js"
//import GLightbox from "glightbox"
//import "glightbox/dist/css/glightbox.css"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context("../images", true)
// const imagePath = (name) => images(name, true)

import Vue from "vue/dist/vue.esm"
import Dropdown from "./components/Dropdown.vue"
import Tabs from "./components/Tabs.vue"
import Tab from "./components/Tab.vue"
import Drawer from "./components/Drawer.vue"

import TurbolinksAdapter from "vue-turbolinks"
Vue.use(TurbolinksAdapter)

document.addEventListener("turbolinks:load", () => {
  const app = new Vue({
    el: "#app",
    data: {},
    created() {
      var scripts = [
        "https://cdn.jsdelivr.net/gh/mcstudios/glightbox/dist/js/glightbox.min.js"
      ];
      scripts.forEach(script => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
    },
    mounted() {
      const lightbox = GLightbox();
    },
    components: { Dropdown, Tabs, Tab, Drawer }
  })

  const jumpTo = document.getElementById("jump-to-packages")
  if (jumpTo) {
    jumpTo.addEventListener("click", function () {
      jump("#packages", { offset: -50 })
    })
  }

  const searchForms = document.getElementsByClassName("search")
  for (var i = 0; i < searchForms.length; i++) {
    searchForms[i].addEventListener("change", (e) => {
      e.target.form.submit()
    })
  }
})

const captchaEnableSubmit = function () {
  //document.getElementById("submit").disabled=false;
  document.getElementById("submit").classList.remove("invisible");
};

const captchaOnLoad = function () {
  //document.getElementById("formDiv").style="visibility: visible;";
  grecaptcha.render('g-recaptcha', {
    'sitekey': '6LcI0NgbAAAAANrrgt38fsKk9JqKlMIZGmJzNaB6'
  });
};

window.captchaEnableSubmit = captchaEnableSubmit;
window.captchaOnLoad = captchaOnLoad;
