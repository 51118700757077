<template>
  <div>
    <button class="md:ml-2 btn-outline" @click="openDrawer">
      <i class="fal fa-bars" data-fa-transform="grow-3"></i>
    </button>
    <transition name="slide">
      <div v-if="open" class="overflow-y-hidden bg-gray-100 fixed top-0 right-0 h-screen z-30">
        <div class="flex flex-col justify-between h-full p-6 md:p-10">
          <span class="text-right text-gray-500 mb-2" @click="closeDrawer">
            <i class="far fa-times fa-2x cursor-pointer"></i>
          </span>

          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "drawer",

  data() {
    return {
      open: false
    };
  },
  methods: {
    openDrawer: function() {
      this.open = true;
    },
    closeDrawer: function() {
      this.open = false;
    }
  }
};
</script>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 150ms ease-in 0s;
}
</style>
