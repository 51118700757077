<template>
  <div class="flex items-center justify-center py-3">
    <div class="relative">
      <div v-if="open" @click="open = false" class="fixed inset-0"></div>
      <button @click="open = !open" class="relative flex items-center focus:outline-none">
        {{ title }} <span class="ml-1 fas fa-caret-down" data-fa-transform="shrink-2 down-2"></span>
      </button>
      <transition
        enter-active-class="transition-all transition-fastest ease-out-quad"
        leave-active-class="transition-all transition-faster ease-in-quad"
        enter-class="opacity-0 scale-70"
        enter-to-class="opacity-100 scale-100"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-70"
      >
        <div v-if="open" class="origin-top-left absolute left-0 mt-2 w-64 bg-purple-500 rounded shadow-md p-4 z-50">
          <slot name="content"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "dropdown",

  props: {
    title: {
      required: true,
      type: String
    },
  },

  data() {
    return {
      open: false
    }
  },
}
</script>

<style>
.origin-top-right {
  transform-origin: top right;
}

.origin-top-left {
  transform-origin: top left;
}

</style>
